<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      absolute
      height="100vh"
      color="#025464"
      dark
    >
      <v-list rounded>
        <v-list-group
          v-for="(item, index) in navItems"
          :key="index"
          no-action
          color="#FFFFFF"
        >
          <template v-slot:activator>
            <v-list-item-content class="font-weight-bold">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(child, index) in item.children"
            :key="index"
            link
            :to="child.link"
            class="px-5"
          >
            <v-list-item-icon>
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app dark color="#025464">
      <v-app-bar-nav-icon
        v-if="!$route.name?.includes('login')"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        class="font-weight-bold text-xs-caption text-sm-h6 text-md-h5"
      >
        <v-row no-gutters align="center">
          <div class="align-center">{{ pageTitle }}</div>
        </v-row>
      </v-toolbar-title>
    </v-app-bar>
    <v-main> <router-view /></v-main>
    <v-footer dark padless>
      <v-card
        flat
        tile
        color="#025464"
        class="white--text text-center"
        width="100vw"
      >
        <v-card-text class="white--text pa-1">
          <div class="ma-2">
            永扶科技有限公司版權所有 © {{ new Date().getFullYear() }} ,
            <strong class="white--text">
              Always Support Technology. All Rights Reserved.
            </strong>
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    pageTitle: "AST Senior Living Genie",
    dialog: false,
    drawer: false,
    nav: [],
    icons: [
      { url: "https://www.asttw.com/", icon: "mdi-web" },
      {
        url: "https://www.facebook.com/AlwaysSupportTech",
        icon: "mdi-facebook",
      },
      {
        url: "https://www.youtube.com/@AlwaysSupportTech",
        icon: "mdi-youtube",
      },
    ],
  }),
  methods: {
    toURL(url) {
      window.open(url);
    },
    updateTitle() {
      const currentRoute = this.$route.name;
      const matchingNavItem = this.navItems
        .flat()
        .find((item) =>
          item.children.some((child) => child.link === currentRoute)
        );
      if (matchingNavItem) {
        const activeChild = matchingNavItem.children.find(
          (child) => child.link === currentRoute
        );
        if (activeChild) {
          this.pageTitle = `AST Senior Living Genie - ${activeChild.title}`;
        }
      } else {
        this.pageTitle = "AST Senior Living Genie";
      }
    },
  },
  computed: {
    navItems() {
      const routesToHideNav = ["contactbook", "contactbook-login"];
      if (routesToHideNav.includes(this.$route.name)) {
        this.nav = [];
      } else {
        this.nav = [
          {
            text: "個案管理",
            children: [
              {
                icon: "mdi-bulletin-board",
                title: "電子白板",
                link: "/whiteboard",
              },
              {
                icon: "mdi-smart-card",
                title: "個案總覽",
                link: "/case-dashboard",
              },
              {
                icon: "mdi-fountain-pen-tip",
                title: "生理數據",
                link: "/case-management",
              },
              {
                icon: "mdi-file-document-edit",
                title: "個案日誌",
                link: "/case-daily-record",
              },
              {
                icon: "mdi-bus-double-decker",
                title: "交通車簽到",
                link: "/traffic",
              },
              {
                icon: "mdi-file-clock",
                title: "簽到退紀錄",
                link: "/checkinsheet",
              },
              // {
              //   icon: "mdi-account-plus",
              //   title: "個案建檔",
              //   link: "/snapshot",
              // },
              {
                icon: "mdi-account-plus",
                title: "個案列表",
                link: "/case-list",
              },
              {
                icon: "mdi-account-plus",
                title: "員工列表",
                link: "/staff-list",
              },
              {
                icon: "mdi-account-plus",
                title: "主責照服管理",
                link: "/care-management",
              },
            ],
          },
          {
            text: "衛材管理",
            children: [
              {
                icon: "mdi-bag-personal",
                title: "衛材登記",
                link: "/material-management",
              },
              {
                icon: "mdi-account-cash",
                title: "每月結算",
                link: "/material-settlement",
              },
            ],
          },
          {
            text: "其他",
            children: [
              {
                icon: "mdi-map-marker-check",
                title: "差勤系統",
                link: "/employee-attendance",
              },
              {
                icon: "mdi-file-clock",
                title: "旅遊儀錶板",
                link: "/travel-dashboard",
              },
            ],
          },
        ];
      }
      return this.nav;
    },
  },
  watch: {
    // $route(to, from) {
    //   this.updateTitle();
    // },
  },
  mounted() {
    // this.updateTitle();
  },
};
</script>
<style scoped>
.v-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

::v-deep .v-application--wrap {
  background: #f2f2f2;
}

router-view {
  flex: 1;
}

.v-footer {
  margin-top: auto;
}
</style>
