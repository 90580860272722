<template>
  <div class="ma-auto">
    <div
      class="text-h4 pa-4 font-weight-bold text-center"
      style="color: #643a6b"
    >
      家庭聯絡簿
    </div>
    <v-card elevation="0" class="mx-auto pa-2" color="#f2f2f2" width="90%">
      <div class="text-h5 font-weight-bold" style="color: #643a6b">
        健康資訊
      </div>
      <v-divider class="my-2" />
      <v-row no-gutters justify="center" align="center">
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartHeartRate :data="dataHeartRate" :label="labelDate" />
        </v-col>
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartBP
            :dataShrink="dataShrink"
            :dataRelax="dataRelax"
            :label="labelDate"
          />
        </v-col>
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartTemperature
            :dataTemperature="dataTemperature"
            :label="labelDate"
          />
        </v-col>
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartBloodSugar
            :dataBloodSugar="dataBloodSugar"
            :label="labelDate"
          />
        </v-col>
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartSpO2 :dataSpO2="dataSpO2" :label="labelDate" />
        </v-col>
        <v-col class="d-flex justify-center pa-2" cols="12" lg="6">
          <ChartWeight :dataWeight="dataWeight" :label="labelDate" />
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="0" class="mx-auto my-2 pa-2" color="#f2f2f2" width="90%">
      <div class="text-h5 font-weight-bold" style="color: #643a6b">
        機構的話
      </div>
      <v-divider class="my-2" />
      <span class="px-4 font-weight-bold">
        爺爺今天午餐時食慾不佳，沒有吃什麼東西，下午時情緒也較為不穩定
      </span>
    </v-card>
    <v-card elevation="0" class="mx-auto my-2 pa-2" color="#f2f2f2" width="90%">
      <v-row dense>
        <div class="text-h5 font-weight-bold" style="color: #643a6b">
          預約探視與請假
        </div>
        <!-- <v-btn class="mx-4 white--text" color="#313866" @click="handleDialog">
          <v-icon>mdi-calendar-plus</v-icon>
          新增
        </v-btn> -->
      </v-row>
      <v-divider class="my-2" />
      <v-row dense>
        <v-col
          v-for="event in events"
          :key="event.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card
            class="pa-1"
            :disabled="event.past"
            :color="event.past ? '#b6b6b6' : ''"
          >
            <v-row dense justify="center">
              <v-col cols="4" class="text-center">
                <div class="text-subtitle-1 font-weight-bold">
                  {{ formatDate(event.start) }}
                </div>
                <div>{{ formatTime(event.start) }}</div>
                <div>-</div>
                <div>{{ formatTime(event.end) }}</div>
              </v-col>
              <v-col cols="8" class="px-4 align-self-center">
                <div class="text-h6 font-weight-bold">
                  {{ event.description }}
                </div>
                <div class="text-caption font-weight-bold">
                  <v-chip
                    class="white--text"
                    :color="
                      event.event_type === '隔離'
                        ? '#0E21A0'
                        : event.event_type === '探視'
                        ? '#4D2DB7'
                        : event.event_type === '請假'
                        ? '#9D44C0'
                        : '#EC53B0'
                    "
                    x-small
                  >
                    {{ event.event_type }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-2">
        <v-card-text class="pa-2">
          <v-row dense>
            <v-col cols="12" class="pa-2">
              <v-select
                outlined
                dense
                :items="eventTypes"
                label="事件類型"
                hide-details=""
                v-model="event_type"
              ></v-select></v-col
            ><v-col cols="12" class="pa-2">
              <v-text-field
                outlined
                dense
                label="事由"
                hide-details=""
                v-model="description"
              ></v-text-field> </v-col
            ><v-col cols="12" class="pa-2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="date"
                    label="選擇日期"
                    hide-details=""
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu> </v-col
            ><v-col cols="12" class="pa-2">
              <v-select
                outlined
                dense
                :items="timeOptions"
                label="選擇時間"
                hide-details=""
                v-model="time"
              ></v-select
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-2 justify-center">
          <v-btn color="blue" class="white--text" @click="addEvents"
            >送出</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import ChartBloodSugar from "@/components/ChartBloodSugar.vue";
import ChartBP from "@/components/ChartBP.vue";
import ChartHeartRate from "@/components/ChartHeartRate.vue";
import ChartSpO2 from "@/components/ChartSpO2.vue";
import ChartTemperature from "@/components/ChartTemperature.vue";
import ChartWeight from "@/components/ChartWeight.vue";

export default {
  components: {
    ChartBloodSugar,
    ChartBP,
    ChartHeartRate,
    ChartSpO2,
    ChartTemperature,
    ChartWeight,
  },
  data() {
    return {
      id: "",
      name: "",
      dataBloodSugar: [],
      dataShrink: [],
      dataRelax: [],
      dataHeartRate: [],
      dataSpO2: [],
      dataTemperature: [],
      dataWeight: [],
      labelDate: [],
      events: [],
      dialog: false,
      eventTypes: ["送醫", "請假", "探視", "隔離"],
      event_type: null,
      description: "",
      date: new Date().toLocaleDateString().replaceAll("/", "-"),
      menu: false,
      timeOptions: this.generateTimeOptions(),
      time: null,
    };
  },
  methods: {
    async getWeeklyData() {
      const now = new Date();
      let formattedTime = moment(now).format();
      const encodedISOString = encodeURIComponent(formattedTime);
      const axios = require("axios");
      let config = {
        method: "get",
        url:
           "/api/attendance/contacts/" +
          this.id +
          "/?datetime=" +
          encodedISOString,
        header: {},
      };
      try {
        let res = await axios.request(config);
        console.log(res.data);
        if (res.data.vital_sign.length > 0) {
          res.data.vital_sign.forEach((element) => {
            let datetime = element.start;
            datetime = moment(datetime).local().format("MM/DD");
            this.labelDate.push(datetime);
            this.dataBloodSugar.push(element.blood_sugar);
            this.dataShrink.push(element.shrink);
            this.dataRelax.push(element.relax);
            this.dataHeartRate.push(element.hr);
            this.dataSpO2.push(element.SPO2);
            this.dataTemperature.push(element.temperature);
            this.dataWeight.push(element.weight);
          });
        }
        this.events = res.data.event;
      } catch (error) {
        console.log(error);
      }
    },
    async addEvents() {
      let start = moment(
        this.date + " " + this.time,
        "YYYY-M-DD HH:mm"
      ).toDate();
      let end = moment(start).add(1, "hours").toDate();
      let formattedStart = moment(start).format();
      let formattedEnd = moment(end).format();
      const encodedStart = encodeURIComponent(formattedStart);
      const encodedEnd = encodeURIComponent(formattedEnd);
      const axios = require("axios");
      const qs = require("qs");
      let data = qs.stringify({
        event_type: this.event_type,
        description: this.description,
        start: formattedStart,
        end: formattedEnd,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url:  `/api/attendance/events/${this.id}/`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      try {
        let res = await axios.request(config);
        console.log(res.data);
        this.getWeeklyData();
        this.dialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    handleDialog() {
      this.event_type = "";
      this.description = "";
      this.date = new Date().toLocaleDateString().replaceAll("/", "-");
      this.time = "";
      this.dialog = true;
    },
    generateTimeOptions() {
      const options = [];
      for (let i = 8; i <= 18; i++) {
        const time = i < 10 ? `0${i}:00` : `${i}:00`;
        options.push(time);
      }
      return options;
    },
    formatDate(dateTimeStr) {
      const date = new Date(dateTimeStr);
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${month}/${day}`;
    },
    formatTime(dateTimeStr) {
      const date = new Date(dateTimeStr);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    },
  },
  mounted() {
    this.getWeeklyData();
  },
  created() {
    this.id = this.$route.params.id;
  },
};
</script>
<style scoped></style>
