import { render, staticRenderFns } from "./App.vue?vue&type=template&id=058c0f84&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=058c0f84&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058c0f84",
  null
  
)

export default component.exports