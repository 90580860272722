<template>
  <div class="custom-margin">
    <v-row dense class="ma-2">
      <div>
        <v-btn color="#141E46" class="white--text" @click="getCurrentDate">
          今日
        </v-btn>
      </div>
      <div class="mx-2">
        <v-row dense>
          <v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="prevDay"
              elevation="0"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn></v-col
          ><v-col class="mx-n4">
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="menu = !menu"
              elevation="0"
            >
              {{ formattedDate }}
            </v-btn>
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
              ><template v-slot:activator="{ on }">
                <div v-on="on"></div>
              </template>
              <v-date-picker
                v-model="formattedDate"
                :value="selectedDate"
                @input="menu = false"
              ></v-date-picker> </v-menu></v-col
          ><v-col>
            <v-btn
              tile
              color="#BB2525"
              class="white--text"
              @click="nextDay"
              elevation="0"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn></v-col
          ></v-row
        >
      </div>
    </v-row>
    <div class="ma-2 table-row">
      <v-data-table
        :headers="headers"
        :items="careLogs"
        :items-per-page="-1"
        class="pa-2 table"
        disable-sort
        hide-default-footer
        dense
      >
        <template v-slot:item.has_written="{ item }">
          <v-icon
            large
            :color="item.has_written ? 'green' : 'grey'"
            class="mr-2"
          >
            mdi-file-document-check
          </v-icon>
        </template>
        <template v-slot:item.viewRecord="{ item }">
          <v-icon large class="mr-2" @click="viewRecord(item)">
            mdi-eye
          </v-icon>
        </template>
        <template v-slot:item.addRecord="{ item }">
          <v-icon large @click="addRecord(item)"> mdi-pencil </v-icon>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="view_record_dialog" persistent fullscreen>
      <v-toolbar dark color="#025464">
        <v-btn icon dark @click="close_record_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >查看日誌紀錄 - {{ selected_case_name }}</v-toolbar-title
        >
      </v-toolbar>
      <v-card height="auto">
        <v-row dense justify="center"
          ><v-col cols="12" sm="auto"
            ><v-card-title class="font-weight-bold"
              >請選擇要查看的日期</v-card-title
            ></v-col
          ><v-col cols="12" sm="auto">
            <v-select
              dense
              outlined
              :items="availableDates"
              placeholder="選擇日期"
              v-model="view_date"
              class="pa-2"
              hide-details=""
              @change="viewSelectedDateRecord"
            ></v-select></v-col
        ></v-row>
        <v-divider class="ma-2" />
        <v-card v-if="view_date" elevation="0">
          <v-card-title>
            <v-row dense
              ><v-col class="text-center" cols="12"
                ><span>個案姓名: </span
                ><span class="font-weight-bold">{{
                  selected_case_name
                }}</span></v-col
              >
              <v-col class="text-center" cols="12"
                ><span>紀錄日期: </span><span>{{ this.view_date }}</span></v-col
              ></v-row
            >
          </v-card-title>
          <v-card-text class="text-center">
            <v-container>
              <v-row>
                <v-col
                  v-for="(item, key) in contents"
                  :key="key"
                  cols="6"
                  sm="4"
                  md="3"
                >
                  <div class="text-h6 font-weight-bold black--text">
                    {{ item.question }}
                  </div>
                  <div>
                    {{ item.answer }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="add_record_dialog" persistent fullscreen>
      <v-toolbar dark color="#025464">
        <v-btn icon dark @click="add_record_dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>填寫新日誌 - {{ selected_case_name }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-title>
          <v-row dense
            ><v-col class="text-center" cols="12"
              ><span>個案姓名: </span
              ><span class="font-weight-bold">{{
                selected_case_name
              }}</span></v-col
            >
            <v-col class="text-center" cols="12"
              ><span>紀錄日期: </span
              ><span>{{ this.formattedDate }}</span></v-col
            >
            <v-col class="text-center" cols="12"
              ><v-btn
                class="font-weight-bold black--text"
                color="#00e6a0"
                @click="getLastRecord(case_num)"
                >匯入前次紀錄</v-btn
              ></v-col
            ></v-row
          >
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row dense v-for="(item, key) in contents" :key="key">
                <v-col cols="12">
                  <span class="font-weight-bold black--text">{{
                    item.question
                  }}</span></v-col
                >
                <v-col cols="12">
                  <template v-if="item.options && item.options.length">
                    <v-btn-toggle
                      dense
                      v-model="item.answer"
                      style="flex-wrap: wrap"
                    >
                      <v-btn
                        v-for="option in item.options"
                        :key="option"
                        :value="option"
                        color="#ffb200"
                        class="font-weight-bold"
                        active-class="selected-option"
                        >{{ option }}</v-btn
                      >
                    </v-btn-toggle>
                  </template>
                  <v-textarea
                    v-else
                    dense
                    filled
                    clearable
                    v-model="item.answer"
                  ></v-textarea
                ></v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="mx-4"
            color="red darken-1"
            text
            @click="add_record_dialog = false"
            >取消</v-btn
          >
          <v-btn class="mx-4" color="blue darken-1" text @click="submitForm"
            >送出</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "姓名",
          align: "start",
          sortable: false,
          value: "name",
          align: "center",
        },
        {
          text: "填寫狀態",
          value: "has_written",
          align: "center",
        },
        {
          text: "查看紀錄",
          value: "viewRecord",
          align: "center",
        },
        {
          text: "填寫新日誌",
          value: "addRecord",
          align: "center",
        },
      ],
      careLogs: [],
      menu: false,
      selectedDate: new Date(),
      add_record_dialog: false,
      contents: {},
      staff_id: "",
      case_num: "",
      view_record_dialog: false,
      availableDates: [],
      view_date: "",
      selected_case_num: "",
      selected_case_name: "",
    };
  },
  methods: {
    async getList() {
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/journal/get_all_record?date=${this.formattedDate}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        this.careLogs = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getRecord(case_num, date) {
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/journal/search_record?case_num=${case_num}&date=${date}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        this.contents = res.data.data;
        console.log(this.contents);
      } catch (error) {
        console.log(error);
      }
    },
    async makeRecord(case_num) {
      const axios = require("axios");
      let data = JSON.stringify({
        case_num: case_num,
        employee_id: this.staff_id,
        date: this.formattedDate,
        ...this.contents,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/journal/make_record",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      try {
        let res = await axios.request(config);
        console.log(`${res.data.status}, ${res.data.data}`);
        this.getList();
      } catch (error) {
        console.log(error);
      }
    },
    async getRecordList(case_num) {
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/journal/get_person_record_list?case_num=${case_num}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        this.availableDates = res.data.data;
        console.log(this.availableDates);
      } catch (error) {
        console.log(error);
      }
    },
    async getLastRecord(case_num) {
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/journal/search_latest_record?case_num=${case_num}`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        const { record_id, ...others } = res.data.data;
        this.contents = others;
        console.log(this.contents);
      } catch (error) {
        console.log(error);
      }
    },
    viewRecord(item) {
      this.selected_case_num = item.case_num;
      this.selected_case_name = item.name;
      this.getRecordList(this.selected_case_num);
      this.view_record_dialog = true;
    },
    viewSelectedDateRecord() {
      this.getRecord(this.selected_case_num, this.view_date);
    },
    close_record_dialog() {
      this.view_date = "";
      this.contents = {};
      this.view_record_dialog = false;
    },
    addRecord(item) {
      this.selected_case_name = item.name;
      this.case_num = item.case_num;
      this.getRecord(this.case_num, this.formattedDate);
      this.add_record_dialog = true;
    },
    getCurrentDate() {
      this.selectedDate = new Date();
    },
    prevDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() - 1);
      this.selectedDate = new Date(this.selectedDate);
    },
    nextDay() {
      this.selectedDate.setDate(this.selectedDate.getDate() + 1);
      this.selectedDate = new Date(this.selectedDate);
    },
    submitForm() {
      this.makeRecord(this.case_num);
      this.add_record_dialog = false;
    },
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.selectedDate.getFullYear();
        const month = this.selectedDate.getMonth() + 1;
        const day = this.selectedDate.getDate();
        return `${year}-${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`;
      },
      set(newValue) {
        const [year, month, day] = newValue.split("-").map(Number);
        this.selectedDate = new Date(year, month - 1, day);
      },
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    selectedDate() {
      this.getList();
    },
  },
  created() {
    let staff = sessionStorage.getItem("staff");
    this.staff_id = JSON.parse(staff).id;
  },
};
</script>
<style scoped>
.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 75vw;
}

.table {
  width: 100%;
}

.selected-option {
  background-color: #0078ff !important;
  color: white !important;
}
</style>
